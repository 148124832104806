<template>
  <div>
    <v-row justify="center" style="text-align: center" class="mt-10">
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        v-if="step == 'input_email'"
      >
        <h2>{{ "sign_in_button_reset_password" | localize }}</h2>
        <input-email @emailCheck="emailCheck" ref="input_email" />
      </v-col>
      <v-col
        v-if="step == 'check_email'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        class="px-5"
      >
        <check-email :email="email" @success="step = 'input_new_password'" />
      </v-col>
      <v-col
        v-if="step == 'input_new_password'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <h2>{{ "reset_password_label_please_input_password" | localize }}</h2>
        <new-password-form @success="step = 'show_success_page'" />
      </v-col>
      <v-col v-if="step == 'show_success_page'">
        <reset-password-success />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import inputEmail from "./inputEmail.vue";
import checkEmail from "./checkEmail.vue";
import resetPasswordSuccess from "./resetPasswordSuccess.vue";
import newPasswordForm from "./newPasswordForm.vue";
import AuthService from "../../requests/Auth/AuthService";
export default {
  components: {
    inputEmail,
    checkEmail,
    newPasswordForm,
    resetPasswordSuccess,
  },
  data: () => ({
    step: "input_email",
    showNewPasswordForm: false,
    email: "",
    resetPasswordSuccess: false,
  }),
  methods: {
    async emailCheck(email) {
      this.email = email;
      let form = new FormData();
      form.append("email", email);
      form.append("client", "citypass");

      await AuthService.resetPasswordUserEmail(form)
        .then((response) => {
          console.log("response", response);
          if (response.status == "success") {
            this.step = "check_email";
          }
        })
        .catch((e) => {
          if (e.response.data.error_code == "user_not_found") {
            this.$refs.input_email.setMessage("user_not_found");
          } else {
            this.$refs.input_email.setMessage();
          }
        });
    },
  },
};
</script>

<style>
</style>