<template>
  <v-col class="text-left">
    <v-text-field
      placeholder="Type your email here.."
      :label="'faq_placeholder_email' | localize"
      outlined
      dense
      color="#e62076"
      :error-messages="emailError | localize"
      :hide-details="!emailError.length"
      @input="message = []"
      @blur="$v.email.$touch()"
      v-model="email"
    />
    <span style="font-size: 12px; color: red; text-align: left">{{
      message[0] | localize
    }}</span>
    <v-row justify="center" class="px-3 mt-5">
      <v-btn
        color="#e62076"
        class="white--text"
        block
        @click="!$v.$invalid ? $emit('emailCheck', email) : $v.email.$touch()"
        >{{ "reset_password_button_submit" | localize }}</v-btn
      >
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data: () => ({
    email: "",
    message: [],
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    setMessage(message) {
      if (message == "user_not_found") {
        this.message.push("validation_not_existing_email");
      } else {
        this.message.push("reset_password_validation_incorrect_email");
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      !this.$v.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
  },
};
</script>

<style>
</style>