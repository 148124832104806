<template>
  <v-row justify="center">
    <span>{{ "reset_password_label_open_email_for_code" | localize }}</span>
    <v-container>
      <v-row justify="center" class="mt-2">
        <v-otp-input
          length="6"
          v-model.trim="code"
          type="number"
          class=""
          autofocus
          style="max-width: 600px"
        ></v-otp-input>
        <span style="color: #c92424">{{ message }}</span>
      </v-row>
    </v-container>
    <v-container class="ml-10 mt-10">
      <v-row justify="end">
        <v-btn
          :style="'background: #e62076'"
          class="br-10 white--text"
          text
          @click="checkCode()"
        >
          {{ "cart_details_button_next" | localize }}
        </v-btn>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import AuthService from "@/requests/Auth/AuthService";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    code: null,
    message: "",
  }),
  props: {
    email: {
      require,
    },
  },
  methods: {
    ...mapActions(["updateInfo"]),
    async checkCode() {
      let form = new FormData();
      form.append("code", this.code.toString());
      form.append("email", this.email);
      await AuthService.checkResetPasswordCode(form)
        .then((res) => {
          this.updateInfo({
            token: res.data.token,
          });
          this.$emit("success");
        })
        .catch((e) => {
          if (e.response.data.error_code == "user_not_found") {
            this.message = "validation_not_existing_email";
          }
          this.message = e.response.data.message;
        });
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
</style>