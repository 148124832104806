<template>
  <div>
    <v-row justify="center" class="mt-10 mb-10">
      <v-col style="text-align: center">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <img src="@/assets/checkoutSuccess.svg" alt="checkoutSuccess" />
        </div>
        <div class="my-5">
          {{ "reset_password_label_changed_successfully" | localize }}
        </div>
        <v-row justify="center" align="center">
          <span>{{ "reset_password_label_now_you_can" | localize }}</span>
          <span
            ><v-btn
              color="#e62076"
              class="pa-0 mb-0"
              dark
              text
              @click="loginModal = true"
              ><strong>{{
                "sign_in_heading_sign_in" | localize
              }}</strong></v-btn
            ></span
          >
        </v-row>
      </v-col>
    </v-row>
    <login-modal v-if="loginModal" @close_modal="loginModal = false" />
  </div>
</template>

<script>
import LoginModal from "../UI/LoginModal.vue";

export default {
  components: {
    LoginModal,
  },
  data: () => ({
    loginModal: false,
  }),
};
</script>

<style>
</style>