<template>
  <v-main>
    <v-container>
        <reset-password-component />
    </v-container>
  </v-main>
</template>

<script>
import resetPasswordComponent from '../components/ResetPassword/resetPasswordComponent.vue'
export default {
    components: {
        resetPasswordComponent
    }
}
</script>

<style>

</style>