<template>
  <v-col>
    <v-text-field
      outlined
      dense
      :label="'placeholder_new_password' | localize"
      placeholder="Please type new password.."
      color="#e62076"
      v-model="password"
      :error-messages="passwordError | localize"
      @blur="$v.password.$touch()"
      :type="passwordVisible ? 'text' : 'password'"
      :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="passwordVisible = !passwordVisible"
    />
    <v-text-field
      outlined
      dense
     :label="'reset_password_placeholder_confirm_password' | localize"
      placeholder="Please type new confirm password.."
      :error-messages="confirmPasswordError | localize"
      color="#e62076"
      v-model="confirm_password"
      @blur="$v.confirm_password.$touch()"
      :type="confirmPasswordVisible ? 'text' : 'password'"
      :append-icon="confirmPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="confirmPasswordVisible = !confirmPasswordVisible"
    />
    <v-row justify="end">
      <v-btn
        color="#e62076"
        class="white--text mt-3 mr-3"
        @click="changePassword"
      >
        {{'reset_password_button_change' | localize}}
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import AuthService from "../../requests/Auth/AuthService";
export default {
  mixins: [validationMixin],
  data: () => ({
    password: "",
    confirm_password: "",
    passwordVisible: false,
    confirmPasswordVisible: false,
  }),
  validations: {
    password: {
      required,
      minLength: minLength(8),
      validUppercase: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        return containsUppercase;
      },
      validLowercase: function (value) {
        const containsLowercase = /[a-z]/.test(value);
        return containsLowercase;
      },
      validNumber: function (value) {
        const containsNumber = /[0-9]/.test(value);
        return containsNumber;
      },
      validSpecial: function (value) {
        const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
        return containsSpecial;
      },
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
        return (
          containsUppercase &&
          containsLowercase &&
          containsNumber &&
          containsSpecial &&
          minLength
        );
      },
    },
    confirm_password: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    async changePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("token", this.user.token);
        form.append("password", this.password);
        let response = await AuthService.changePassword(form);
        if (response.status == "success") {
          this.$emit("success");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (
        (!this.$v.password.valid && this.$v.password.required) ||
        (!this.$v.password.minLength && this.$v.password.required)
      ) {
        errors.push("sign_up_validation_weak_password");
        return errors;
      }
      !this.$v.password.required && errors.push("sign_up_validation_password_required");
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.confirm_password.sameAsPassword) {
        errors.push("sign_up_validation_password_doesnt_match");
        return errors;
      }
      !this.$v.confirm_password.required &&
        errors.push("my_account_new_password_required");
      return errors;
    },
  },
};
</script>

<style>
</style>